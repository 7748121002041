// Show and hide activity spinner
export const UI_START_LOADING = "UI_START_LOADING";
export const UI_STOP_LOADING = "UI_STOP_LOADING";

// Update Table Header Color
export const UPDATE_TABLE_HEAD_COLOR = "UPDATE_TABLE_HEAD_COLOR";
export const UPDATE_TABLE_NARROW = "UPDATE_TABLE_NARROW";
export const UPDATE_TABLE_HOVERABLE = "UPDATE_TABLE_HOVERABLE";
export const UPDATE_TABLE_FULLWIDTH = "UPDATE_TABLE_FULLWIDTH";

// Update Theme Color
export const UPDATE_THEME_PRIMARY_COLOR = "UPDATE_THEME_PRIMARY_COLOR";
export const UPDATE_THEME_SECONDARY_COLOR = "UPDATE_THEME_SECONDARY_COLOR";
export const UPDATE_INFO_COLOR = "UPDATE_INFO_COLOR";
export const UPDATE_SUCCESS_COLOR = "UPDATE_SUCCESS_COLOR";
export const UPDATE_DANGER_COLOR = "UPDATE_DANGER_COLOR";
export const UPDATE_WARNING_COLOR = "UPDATE_WARNING_COLOR";
